import {ADD_ACTION, SET_DISABLED, SET_PROCESSING} from '@/core/services/store/actions.module';
import { v4 as uuidv4 } from 'uuid';
import {RESET_PROCESS, SET_PROCESS} from '@/core/services/store/processing.module';

export const PRINOR_ACTIONS = {
	create: function(that, title = '', click = function() {}, variant = 'primary', icon = '', processing = false, disabled = false) {
		let id = uuidv4();
		that.$store.dispatch(ADD_ACTION, [
			{
				id: id,
				title: title,
				click: click,
				variant: variant,
				icon: icon,
				processing: processing,
				disabled: disabled,
			},
		]);
		return id;
	},
	setProcessing: function(that, id, value) {
		that.$store.dispatch(SET_PROCESSING, { id: id, processing: value });
	},
	setDisabled: function(that, id, value) {
		that.$store.dispatch(SET_DISABLED, { id: id, disabled: value });
	},
};

export const PRINOR_PROCESSES = {
	reset() {
		window.vueInstance.$store.dispatch(RESET_PROCESS);
	},
	isUpdating() {
		window.vueInstance.$store.dispatch(SET_PROCESS, {
			name: 'isUpdating',
			value: true,
		});
	},
	isLoading() {
		window.vueInstance.$store.dispatch(SET_PROCESS, {
			name: 'isLoading',
			value: true,
		});
	},
	isDeleting() {
		window.vueInstance.$store.dispatch(SET_PROCESS, {
			name: 'isDeleting',
			value: true,
		});
	},
};
